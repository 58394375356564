import {
  axios_ACF,
  axios_AUTH,
  simpleErrorHandling,
} from "config/axiosInstances";
import aggregateParams from "helpers/handelQueryParams";

// getMessagesForSMS
const getSMSMessages = async (market) => {
  try {
    const response = await axios_ACF.get(
      `/api/message/market/${market}/smsMessages`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get messages");
  }
};
// getMessagesForLandingPage
const getLandingPageMessages = async (market) => {
  try {
    const response = await axios_ACF.get(
      `/api/message/market/${market}/landingPageMessages`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get messages");
  }
};
// getMessagesForLandingPage
const getShareAppMessages = async (market) => {
  try {
    const response = await axios_ACF.get(
      `/api/message/market/${market}/share-app`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get messages");
  }
};

// Edit msg by id
const editMessageById = async (msgId) => {
  try {
    const response = await axios_ACF.get(`/api/message/${msgId}`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to load message");
  }
};
// submit edited message
const submitEditMsg = async (msgId, path, body) => {
  try {
    await axios_ACF.post(`/api/message/${msgId}/${path}-request`, body);

    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to edit message");
  }
};
// get all message needs approval "SMS screen tab"
const getApprovalListSms = async () => {
  try {
    const response = await axios_ACF.get(
      `/api/message-edit-request/SMSMessages`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get sms messages list");
  }
};
// get all message needs approval "App screen tab"
const getApprovalListApp = async () => {
  try {
    const response = await axios_ACF.get(
      `/api/message-edit-request/LandingPageMessages`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get app messages list");
  }
};
const getApprovalShareApp = async () => {
  try {
    const response = await axios_ACF.get(`/api/message-edit-request/share-app`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get app messages list");
  }
};

// approve edit
const approveEditedMsg = async (msgId, channel) => {
  try {
    await axios_ACF.patch(
      `/api/message-edit-request/${msgId}/approve${channel}`
    );

    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to approve edited message");
  }
};

// decline edit
const declineEditedMsg = async (msgId, channel) => {
  try {
    await axios_ACF.patch(
      `/api/message-edit-request/${msgId}/decline${channel}`
    );

    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to decline edited message");
  }
};

// get all SerialScan "Notifications screen"
const getScannedSerialsStatus = async (body, selectedLocation) => {
  try {
    const response = await axios_ACF.get(
      `/api/SerialScans?${aggregateParams(body)}${selectedLocation}`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to notifications");
  }
};

// LandingPage
const landingPage = async (scannedCode, country) => {
  try {
    const response = await axios_ACF.post(
      `/api/scan/landingPage?serialNumber=${scannedCode}&countryCode=${country}`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to navigate to the landing page");
  }
};
// get all products to drop it in select field "creat alarm dialog screen "
const getAllProducts = async () => {
  try {
    const response = await axios_ACF.get(
      `/api/alarm/getAvailableAlarmsProducts`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get products");
  }
};
// get all users to drop it in select field "creat alarm dialog screen "
const getAllUsers = async () => {
  try {
    const response = await axios_AUTH.get(`/api/User/GetUsersByCurrentOwner`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarm reports");
  }
};
// get Alarms Notifications

const getAlarmNotifications = async () => {
  try {
    const response = await axios_ACF.get(`/api/alarm/getAlarmsNotifications`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarms notifications");
  }
};
// get Alarms Settings

const getAlarmSettings = async () => {
  try {
    const response = await axios_ACF.get(`/api/alarm/getAlarmsSettings`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarms settings");
  }
};

// get Alarms Cards

const getAlarmCards = async () => {
  try {
    const response = await axios_ACF.get(`/api/AlarmReport/GetAlarmCards`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarms cards");
  }
};

// get all Alarm reports "Reports screen "
const getAlarmReports = async () => {
  try {
    const response = await axios_ACF.get(`/api/AlarmReport`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get Alarm Report list");
  }
};

// create Alarm report "Reports screen"
const createAlarmReport = async (body) => {
  try {
    const response = await axios_ACF.post(`/api/AlarmReport`, body);
    const { data } = response;
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to Add Alarm Report Data");
  }
};

// get  Alarm report "Reports screen "
const getAlarmReportDetails = async (id) => {
  try {
    const response = await axios_ACF.get(`/api/AlarmReport/${id}/Details`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get Alarm Report details");
  }
};
// create Alarm  "alarm dialog screen"
const createAlarm = async (body) => {
  try {
    await axios_ACF.post(`/api/Alarm`, body);
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to Add Alarm Data");
  }
};
// activateAlarm
const activateAlarm = async (alarmId) => {
  try {
    await axios_ACF.post(`/api/alarm/${alarmId}/activateAlarm`);
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to activate alarm");
  }
};

// Deactivate
const deactivateAlarm = async (alarmId) => {
  try {
    await axios_ACF.post(`/api/alarm/${alarmId}/deactivateAlarm`);
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to activate alarm");
  }
};
// /api/alarm/{notificationId}/acknowledgeNotification

const acknowledgeNotification = async (notificationId) => {
  try {
    await axios_ACF.post(
      `/api/alarm/${notificationId}/acknowledgeNotification`
    );
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to acknowledge notification");
  }
};
// get  alarm {alarmName,alarmSeverity} by Notification id "get alarm details by notifcation id screen "

const getAlarmByNotificationId = async (id) => {
  try {
    const response = await axios_ACF.get(
      `/api/alarm/${id}/getByNotificationId`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarm details");
  }
};
// get  alarm {alarmName,alarmSeverity} by alarm id "create Reports screen "
const getAlarmByAlarmId = async (id) => {
  try {
    const response = await axios_ACF.get(`/api/alarm/${id}`);
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get alarm details");
  }
};
// reset alarm notification count
const resetAlarmNotification = async () => {
  try {
    await axios_ACF.get("/api/alarm/Clear");
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to reset alarm notifications");
  }
};

// get all Repeated Serials "Repeated Serials screen "
const getRepeatedSerials = async (body) => {
  try {
    const response = await axios_ACF.get(
      `/api/Dashboard/GetRepeatedSerials?${aggregateParams(body)}`
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    simpleErrorHandling(error, "Failed to get Repeated Serials");
  }
};
// change Repeated Serials "Repeated Serials screen "
const changeRepeatedSerials = async (body) => {
  try {
    await axios_ACF.post(`/api/SerialScan/ChangeRepeatedSerials`, body);
    return true;
  } catch (error) {
    simpleErrorHandling(error, "Failed to Change Repeated Serials");
  }
};

export const counterfeitServices = {
  getSMSMessages,
  getLandingPageMessages,
  getShareAppMessages,
  editMessageById,
  submitEditMsg,
  getApprovalListSms,
  getApprovalListApp,
  getApprovalShareApp,
  approveEditedMsg,
  declineEditedMsg,
  getScannedSerialsStatus,
  landingPage,
  getAllProducts,
  getAllUsers,
  getAlarmNotifications,
  getAlarmSettings,
  getAlarmCards,
  getAlarmReports,
  createAlarmReport,
  getAlarmReportDetails,
  activateAlarm,
  deactivateAlarm,
  createAlarm,
  acknowledgeNotification,
  getAlarmByNotificationId,
  getAlarmByAlarmId,
  resetAlarmNotification,
  getRepeatedSerials,
  changeRepeatedSerials,
};
